<template>
  <div v-if="entity && entity.information">
    <div class="services-header">
      <div>
        <a-radio-group v-model="filterBy">
          <a-radio-button value="all">All</a-radio-button>
          <a-radio-button value="rooms">Rooms</a-radio-button>
          <a-radio-button value="services">Services</a-radio-button>
        </a-radio-group>
      </div>

      <div class="mr-4 d-none d-sm-block kart" v-if="services.length > 0">
        <div
          style="width: 40px; height: 40px; cursor: pointer"
          @click="kartClickHandler"
        >
          <a-badge
            :count="kart.length"
            style="color: #faad15"
            :number-style="{
              backgroundColor: '#faad15',
              color: '#fff',
            }"
          >
            <i class="fe fe-shopping-cart" style="font-size: 24px" />
          </a-badge>
        </div>
      </div>
    </div>

    <div v-if="entity.services && services.length">
      <div v-if="showRooms && rooms.length > 0" style="margin-bottom: 20px">
        <h1>Rooms</h1>

        <div class="grid-container">
          <shared-service-or-room-card
            class="service-card"
            v-for="(service, index) of rooms"
            :key="index"
            :service="service"
            :addedToKart="alreadyInKart(service)"
            @pay-for-service="() => handleServicePayment(service)"
            @add-to-kart="() => addServiceToKart(service)"
            @remove-from-kart="() => removeServiceFromKart(service)"
            @checkout-now="() => checkOutNowClickHandler(service)"
          />
        </div>
      </div>

      <div v-if="showServices && hotelServices.length > 0">
        <h1>Services</h1>

        <div class="grid-container">
          <shared-service-or-room-card
            class="service-card"
            v-for="(service, index) of hotelServices"
            :key="index"
            :service="service"
            :addedToKart="alreadyInKart(service)"
            @pay-for-service="() => handleServicePayment(service)"
            @add-to-kart="() => addServiceToKart(service)"
            @remove-from-kart="() => removeServiceFromKart(service)"
            @checkout-now="() => checkOutNowClickHandler(service)"
          />
        </div>
      </div>

      <shared-payment-modal
        :showModal="showPaymentModal"
        :services="kart"
        :entityId="entityId"
        :entityType="entity_type"
        @close-modal="showPaymentModal = false"
        @cash-payment-processed="showCashPaymentNotificationModal"
      />

      <car-rental-payment-modal
        :showModal="showRentalPaymentModal"
        :services="kart"
        :entityId="entityId"
        :entityType="entity_type"
        @close-modal="closeRentalModalHandler"
        @cash-payment-processed="showCashPaymentNotificationModal"
      />
    </div>

    <div v-else>
      <h4>{{ entity.information.name }} has no Services yet</h4>
    </div>

    <cash-payment-notification-modal
      :showModal="showCashPaymentNotification"
      :paymentCode="cashPaymentCode"
      :transactionId="transactionId"
      @close-modal="closeCashPaymentNotificationHandler"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { cloneDeep, get } from "lodash";
import { GET_ENTITY } from "@/store/actions";

import SharedServiceOrRoomCard from "@/components/tourist/SharedServiceOrRoomCard.vue";
import SharedPaymentModal from "@/components/tourist/SharedPaymentModal";
import CarRentalPaymentModal from "@/components/tourist/CarRentalPaymentModal";
import CashPaymentNotificationModal from "@/components/tourist/CashPaymentNotificationModal";

export default {
  components: {
    SharedServiceOrRoomCard,
    SharedPaymentModal,
    CarRentalPaymentModal,
    CashPaymentNotificationModal,
  },
  props: {
    entity_type: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,

      showPaymentModal: false,
      showRentalPaymentModal: false,
      showCashPaymentNotification: false,

      entity: {},
      currentService: {},
      kart: [],
      cachedKart: [],

      cashPaymentCode: "",
      transactionId: "",
      filterBy: "all",
    };
  },
  computed: {
    services() {
      if (this.entity && this.entity.services) {
        const services = this.entity.services.map((r) => ({
          ...r,
          service_type: "service",
        }));

        let rooms = get(this.entity, "rooms", []);

        rooms = rooms.map((r) => ({
          ...r,
          service_type: "room",
        }));

        return [...services, ...rooms].filter((serviceOrRoom) => {
          if (serviceOrRoom.service_type === "room") {
            return (
              serviceOrRoom.status === "active" &&
              serviceOrRoom.fully_booked === false
            );
          }
          return serviceOrRoom.status === "active";
        });
      }
      return [];
    },
    rooms() {
      return this.services.filter(
        (service) => service.service_type && service.service_type === "room"
      );
    },
    hotelServices() {
      return this.services.filter(
        (service) => !service.service_type || service.service_type !== "room"
      );
    },
    entityId() {
      return this.$route.params.id;
    },
    showRooms() {
      return ["rooms", "all"].includes(this.filterBy);
    },
    showServices() {
      return ["services", "all"].includes(this.filterBy);
    },
  },
  created() {
    this.fetchEntity();
  },
  methods: {
    ...mapActions("tourist", {
      getEntity: GET_ENTITY,
    }),
    ...mapMutations("tourist", {
      setTouristState: "SET_STATE",
    }),
    closeCashPaymentNotificationHandler() {
      this.cashPaymentCode = "";
      this.showCashPaymentNotification = false;
    },
    showCashPaymentNotificationModal({ payment_code, transaction_id }) {
      this.cashPaymentCode = payment_code;
      this.transactionId = transaction_id;
      this.showCashPaymentNotification = true;
    },
    alreadyInKart(service) {
      for (const item of this.kart) {
        if (item._id === service._id) {
          return true;
        }
      }
      return false;
    },
    removeServiceFromKart(service) {
      if (!this.alreadyInKart(service)) {
        this.$notification.info({ message: "Item not in kart!" });
        return;
      }
      this.kart = cloneDeep(this.kart).filter((k) => k._id !== service._id);
    },
    addServiceToKart(service) {
      if (this.alreadyInKart(service)) {
        this.$notification.info({ message: "Item already in kart!" });
        return;
      }
      //What did I do with serviceName? what was I thinking
      const serviceName = service.title ? service.title : service.vehicle_model;
      this.kart.push(service);
    },
    handleServicePayment(service) {
      this.currentService = service;
      this.showPaymentModal = true;
    },
    getServiceTitle(service) {
      if (service.title) return service.title;
      if (service.vehicle_model && service.vehicle_type) {
        const { vehicle_model, vehicle_type } = service;
        return `${vehicle_model} ${vehicle_type}`;
      }
    },
    getFeaturedImage(service) {
      if (service) {
        if (service.featured_images) {
          return this.getServiceFeaturedImage(service);
        }
        // service.featured_image.path
        if (service && service.featured_image) {
          return service.featured_image.path || "";
        }
        return "";
      }
    },
    kartClickHandler() {
      if (this.kart.length === 0) return;
      this.showPaymentModal = !this.showPaymentModal;
    },
    checkOutNowClickHandler(service) {
      if (this.kart.length > 0) {
        this.cachedKart = [...this.kart];
        this.kart = [];
      }
      this.addServiceToKart(service);
      this.showRentalPaymentModal = true;
    },
    getServiceFeaturedImage(service) {
      if (service.featured_images) {
        for (const image of service.featured_images) {
          if (image && image.path && image.path.length > 0) {
            return image.path;
          }
        }
      } else {
        return service.featured_image ? service.featured_image.path : "";
      }
      return "";
    },
    closeRentalModalHandler() {
      this.kart.pop();
      if (this.cachedKart.length > 0) {
        this.kart = [...this.cachedKart];
        this.cachedKart = [];
      }
      this.showRentalPaymentModal = false;
    },
    async fetchEntity() {
      try {
        this.loading = true;
        const response = await this.getEntity({
          entity_type: this.entity_type,
          entity_id: this.$route.params.id,
        });
        this.entity = response.entity;
      } catch (error) {
        this.$notification.error({ message: "Error fetching entity data" });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: auto;
}

.service-card {
  /* margin-left: 5px; */
  margin-right: 25px;
}

.services-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 130px;
  margin-bottom: 20px;
}
</style>
